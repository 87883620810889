html,
body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.raleway-bold {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
.raleway-regular {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

#contact-header::after {
  content: "";
  display: block;
  width: 45%;
  height: 2px;
  background-color: #DE9ADD;
  margin-top: 1rem;
  margin: 10px auto;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
